<template>
  <v-row class="ma-0">
    <v-col>
      <v-card elevation="5" max-width="570" class="mx-auto pa-15" v-if="card">
        <v-card-title class="pa-0 d-flex flex-column align-center">
          <img src="@/assets/img/recover.svg" alt="lock" />
          <v-row>
            <v-col cols="9" class="mx-auto">
              <div class="text-h5 font-weight-bold font-weight-bold mt-7 text-break text-center">Enter new password</div>
              <div class="body-1 mb-4 mt-2 text-break text-center opasity--text">New password must include no less than 8 symbols.</div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-form @submit.prevent="submit" class="px-15" ref="otp" lazy-validation>
            <v-text-field
              v-model="password"
              label="Password"
              :error-messages="passwordErrors"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
              filled
              outlined
              dense
              required
              color="input"
            ></v-text-field>
            <div class="text-center mt-2">
              <v-btn type="submit" width="300" height="48" color="primary">Update password</v-btn>
            </div>
          </v-form>
        </v-card-text>
        <div class="mt-7">
          <v-btn to="/login" text class="text-capitalize secondary--text opasity--text">
            <v-icon left>mdi-arrow-left</v-icon> Back to Sign In
          </v-btn>
        </div>
      </v-card>
      <v-card elevation="5" max-width="570" class="mx-auto pa-15" v-else>
        <v-card-title class="pa-0 d-flex flex-column align-center">
          <img src="@/assets/img/recover.svg" alt="lock" />
          <v-row>
            <v-col cols="9" class="mx-auto">
              <div class="text-h5 font-weight-bold mt-7 text-break text-center">Password reset link expired</div>
              <div class="body-1 mb-4 mt-2 text-break text-center opasity--text">
                Sorry, but time for confirmation a password resenl link expired.
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="flex-column px-15 mt-5">
          <v-btn to="/login" text class="text-capitalize secondary--text opasity--text">
            <v-icon left>mdi-arrow-left</v-icon> Back to Sign In
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    password: { required },
  },
  data() {
    return {
      card: true,
      show: false,
      password: null,
      status: null,
      error: null,
    };
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }
      !this.$v.password.required && errors.push('Please enter new password');
      this.error == 'token__required' && errors.push('Sorry, but token required');
      this.error == 'token__invalid' && errors.push('Email confirmation token is valid');
      this.error == 'token__already_used' && errors.push('Email confirmation token has been already used');
      this.error == 'password__invalid' &&
        errors.push('Please use at least one uppercase letter, one digit, and one of the allowed symbols: ~!@#$%^&*()_+-=|:<>?,.');
      return errors;
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          password: this.password,
          token: this.$route.query.t,
        };
        await this.$store
          .dispatch('resPass', data)
          .then((response) => {
            this.status = response.status;
            this.$router.push('/login');
          })
          .catch((e) => {
            this.status = e.response.status;
            this.error = e.response.data.error[0];
            if (this.error == 'account__suspended') {
              this.$router.push('/account-blocked');
            } else if (this.error == 'token__expired') {
              this.card = !this.card;
            }
          });
      }
    },
  },
};
</script>
